<template>
  <a-tree-select
    v-model:value="value"
    v-model:treeExpandedKeys="expandedKeys"
    :disabled="disabled"
    :placeholder="placeholder"
    :tree-data="treeData"
    :fieldNames="{
      children: 'children',
      title: 'name',
      key: 'key',
      value: 'key',
    }"
    @select="onSelect"
    :show-icon="false"
    class="tree-select-custom"
    dropdownClassName="drop-down-tree-select-group"
  >
    <template #title="{ value: val, name, dataRef, expanded }">
      <div
        class="group inline-flex justify-between items-center w-full py-[18px] transition-custom custom-title"
      >
        <img
          src="@/static/img/group-management/priority-KHAN_CAP.png"
          alt="priority-urgency"
          v-if="dataRef && dataRef.warningLevel === WARNING_LEVEL[0].id"
        />
        <img
          src="@/static/img/group-management/priority-CAO.png"
          alt="priority-high"
          v-else-if="dataRef && dataRef.warningLevel === WARNING_LEVEL[1].id"
        />
        <img
          src="@/static/img/group-management/priority-TRUNG_BINH.png"
          alt="priority-medium"
          v-else-if="dataRef && dataRef.warningLevel === WARNING_LEVEL[2].id"
        />
        <img
          src="@/static/img/group-management/priority-THAP.png"
          alt="priority-low"
          v-else-if="dataRef && dataRef.warningLevel === WARNING_LEVEL[3].id"
        />
        <div class="text-base text-ems-gray300 flex-1 mx-4">
          <span>{{ name }}</span>
        </div>
        <cds-feather-icons
          v-if="dataRef && dataRef.children && dataRef.children.length > 0"
          :type="expanded ? 'chevron-up' : 'chevron-down'"
          size="24"
          class="text-ems-gray600 group-hover:text-ems-gray400"
          @click.stop="!expanded ? onExpand(dataRef) : onCollapse(dataRef)"
        />
      </div>
    </template>
  </a-tree-select>
</template>
<script setup>
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import { WARNING_LEVEL } from '@/config/Constant.js';
import { computed, onMounted, ref, reactive } from 'vue';
import _ from 'lodash';
const emit = defineEmits(['update:modelValue']);

const onSelect = (id, item) => {
  emit('update:modelValue', item);
};

const { state, dispatch } = useStore();

const calcTree = (tree) => {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.id === selectedGroup.value.id) {
      tree.splice(i, 1);
    } else {
      if (node.children && node.children.length > 0) {
        calcTree(node.children);
      }
    }
  }
};
let treeData = ref([]);
let selectedGroup = computed(() => state.ioGroup.selectedGroup);
const treeFlat = computed(() => state.ioGroup.listGroup);
const expandedKeys = ref([]);
onMounted(async () => {
  await dispatch('ioGroup/getAllTreeGroup');
  await dispatch('ioGroup/getAllGroup');
  treeFlat.value.forEach((element) => {
    expandedKeys.value.push(element.id);
  });
  treeData.value = _.cloneDeep(state.ioGroup.treeAllGroup);
  if (selectedGroup.value && props.removeSelectedGroup) {
    calcTree(treeData.value);
  }
});
const onExpand = (data) => {
  expandedKeys.value.push(data.key);
};

const onCollapse = (data) => {
  expandedKeys.value.splice(expandedKeys.value.indexOf(data.key), 1);
};
const props = defineProps({
  disabled: VueTypes.bool.def(false),
  placeholder: VueTypes.string.def(''),
  removeSelectedGroup: VueTypes.bool.def(false),
});
</script>
<style lang="scss">
.tree-select-custom .ant-select-selector .custom-title {
  height: 100%;
  padding: 0;
}
.drop-down-tree-select-group .ant-select-tree-list {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 36, var(--tw-bg-opacity));
  height: 100%;
  padding: 1.25rem;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-switcher {
  display: none;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode {
  padding: 0px;
  width: 100%;
  border-bottom: 1px solid #2e2e2e;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode:hover:before {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode:before {
  height: 100%;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode .ant-select-tree-node-selected {
  background-color: #2B2A3A !important;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode .ant-select-tree-node-content-wrapper:hover {
  background-color: #2B2A3A !important;
}
.drop-down-tree-select-group .ant-select-tree-list .ant-select-tree-treenode .ant-select-tree-node-content-wrapper .ant-select-tree-title {
  width: 100%;
}
</style>
