<template>
  <div class="h-full p-10 group-info">
    <Empty
      v-if="notHaveAccess"
      :description="t('group-management.not-have-access')"
      class="mt-10"
    />
    <template v-else>
      <a-form
        :model="formState"
        layout="vertical"
        class="max-w-[400px] mx-auto"
      >
        <span
          class="text-base text-ems-gray200 font-semibold mb-[15px] block"
          >{{ t('group-management.info') }}</span
        >
        <a-form-item name="name">
          <span class="text-sm text-ems-gray500 mb-1">{{
            t('group-management.name')
          }}</span>
          <a-input
            :disabled="action === ACTION.VIEW"
            type="text"
            v-model:value="formState.name"
            :placeholder="t('group-management.name')"
            class="ant-input-sm"
            :maxlength="100"
            showCount
            allowClear
          />
          <span class="text-sm text-ems-main2 mt-1" v-if="nameRequired">{{
            t('group-management.name-required')
          }}</span>
        </a-form-item>
        <a-form-item name="description">
          <span class="text-sm text-ems-gray500 mb-1">{{
            t('group-management.desc')
          }}</span>
          <a-input
            :disabled="action === ACTION.VIEW"
            type="text"
            v-model:value="formState.description"
            class="ant-input-sm"
            :placeholder="t('group-management.desc')"
            :maxlength="255"
            showCount
            allowClear
          />
        </a-form-item>
        <a-form-item name="parent">
          <span class="text-sm text-ems-gray500 mb-1">{{
            t('group-management.parentName')
          }}</span>
          <SelectGroup
            :value="
              formState.parent && formState.parent.key
                ? formState.parent.key
                : null
            "
            @update:model-value="updateVl"
            :disabled="action === ACTION.VIEW"
            :placeholder="t('group-management.move-other-group')"
            removeSelectedGroup
          />
        </a-form-item>
        <a-form-item class="!mt-[45px]">
          <span
            class="text-base text-ems-gray200 font-semibold mb-[15px] block"
            >{{ t('group-management.priority') }}</span
          >

          <div
            v-if="action !== ACTION.VIEW"
            class="flex gap-x-[10px] gap-y-[24px] justify-start flex-wrap"
          >
            <PriorityItem
              v-for="(data, idx) in priorityList"
              :key="idx"
              :data="data"
              :itemPriority="formState.warningLevel"
              @click="changePriority(data)"
            />
          </div>
          <div v-else>
            <PriorityItem
              :data="priorityList[formState.warningLevel - 1]"
              class="border-1 border-solid !border-ems-main2"
            />
          </div>
          <span class="text-sm text-ems-main2 mt-1" v-if="priorityRequired">{{
            t('group-management.priority-required')
          }}</span>
        </a-form-item>
        <a-form-item class="!mt-[80px]">
          <div class="flex justify-center">
            <ClipButton
              class="flex items-center w-[110px]"
              :type="'primary'"
              v-if="
                action === ACTION.VIEW &&
                checkHasPermission(perms, OBJECT_GROUP_PERM.EDIT)
              "
              @click="edit"
            >
              <span>{{ t('common.edit') }}</span>
            </ClipButton>
            <div
              v-if="
                action !== ACTION.VIEW &&
                checkHasPermission(perms, OBJECT_GROUP_PERM.EDIT)
              "
              class="flex gap-[25px] justify-centter"
            >
              <ClipButton
                class="flex items-center w-[110px]"
                :type="'primary'"
                @click="submit"
              >
                <span>{{ t('common.save') }}</span>
              </ClipButton>
              <div
                class="btn-cancel w-[110px] flex justify-center items-center text-sm text-ems-gray300 cursor-pointer"
                @click="cancel"
              >
                {{ t('group-management.cancel') }}
              </div>
            </div>
          </div>
        </a-form-item>
      </a-form>
    </template>
  </div>
</template>
<script setup>
import { i18n } from '@/main';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { ACTION } from '@/config/Constant.js';
import { computed, onMounted, reactive, ref } from 'vue';
import PriorityItem from './PriorityItem.vue';
import SelectGroup from '@/components/select-group/SelectGroup.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Empty from '@/components/empty/index.vue';
import { checkHasPermission } from '@/util/common-utils';
import { OBJECT_GROUP_PERM } from '@/util/common-constant';

const { state, dispatch } = useStore();
const { t } = i18n.global;
const route = useRoute();
const router = useRouter();
let action = computed(() => state.ioGroup.action);
const perms = computed(() => state.auth.userInfo.permissions);
const notHaveAccess = computed(() => state.ioGroup.notHaveAccess);
const priorityList = reactive(state.ioGroup.warningLevel);
const priorityRequired = ref(false);
const nameRequired = ref(false);
const submit = async () => {
  if (!formState.name) {
    nameRequired.value = true;
  } else nameRequired.value = false;
  if (!formState.warningLevel) {
    priorityRequired.value = true;
  } else priorityRequired.value = false;
  if (!priorityRequired.value && !nameRequired.value) {
    if (await dispatch('ioGroup/update', formState)) {
      await dispatch('ioGroup/getTreeGroup');
      dispatch('ioGroup/changeAction', ACTION.VIEW);
      router.push({
        path: '/io-management/group-management',
        query: { ...route.query, action: ACTION.VIEW },
      });
    }
  }
};
const formState = reactive({
  parent: '',
  name: '',
  description: '',
  warningLevel: null,
});
let treeData = computed(() => state.ioGroup.treeGroup);

const getParent = (id, tree) => {
  let parent;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.id === id)) {
        parent = node;
      } else if (getParent(id, node.children)) {
        parent = getParent(id, node.children);
      }
    }
  }
  return parent;
};
const setData = () => {
  formState.warningLevel = props.selectedGroup.warningLevel;
  formState.parent = getParent(props.selectedGroup.id, treeData.value);
  formState.name = props.selectedGroup.name;
  formState.description = props.selectedGroup.description;
};
onMounted(() => {
  setData();
});
const props = defineProps({
  selectedGroup: {
    type: Object,
    default: {},
  },
});
const updateVl = (newValue) => {
  formState.parent = newValue;
};
const cancel = () => {
  setData();
  dispatch('ioGroup/changeAction', ACTION.VIEW);
  router.push({
    path: '/io-management/group-management',
    query: { ...route.query, action: ACTION.VIEW },
  });
};
const edit = () => {
  dispatch('ioGroup/changeAction', ACTION.EDIT);
  router.push({
    path: '/io-management/group-management',
    query: { ...route.query, action: ACTION.EDIT },
  });
};
const changePriority = (priority) => {
  formState.warningLevel = priority.id;
};
</script>
<style>
.group-info .btn-cancel {
  background-image: url('../../../../static/img/group-management/btn-cancel.png');
  background-size: auto;
  background-repeat: round;
}
.group-info .ant-input-sm {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(112, 111, 128, var(--tw-border-opacity)) !important;
}
.group-info .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
  border: none !important;
}
.group-info .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.group-info .ant-select {
  height: 38px;
}
.group-info .ant-select-selector {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity)) !important;
  border-width: 1px !important;
  border-radius: 0 !important;
}
</style>
